<template>
  <div>
    <v-row v-if="tournament !== undefined">
      <v-col
        md="12"
        cols="12"
      >
        <v-card>
          <v-img
            class="white--text align-center"
            src="@/assets/images/header/csgo-series-dark2.png"
            height="100%"
          >
            <v-card-title class="pt-0 px-0 pl-5 tournament-title">
              {{ tournament.name }}
            </v-card-title>
            <div class="pl-4 pr-4 registered-teams">
              <div class="d-flex justify-space-between align-center">
                <span class="font-weight-medium registration-badge">
                  {{ tournament.team_count }} / {{ tournament.slots }} teams registered
                </span>
              </div>
            </div>
            <v-col
              cols="12"
              md="12"
              class="pb-12"
            >
              <v-btn
                color="mainbtn"
                class="ml-2 mr-1"
                :href="tournament.url.replace('{lang}', 'en')"
                target="_blank"
              >
                Event Page
              </v-btn>
              <!-- <v-btn
                color="mainbtn"
                class="ml-1 mr-1"
              >
                Pay
              </v-btn> -->
              <v-btn
                color="mainbtn"
                class="ml-1 mr-1"
                :href="tournament.url.replace('{lang}', 'en') + '/rules'"
                target="_blank"
              >
                Rulebook
              </v-btn>
            </v-col>
            <v-row>
              <v-col
                v-if="tournament.head_sponsor !== undefined"
                md="2"
                sm="2"
                cols="12"
                class="align-self-start mt-8 ml-2"
              >
                <div class="text-left ml-4">
                  Head Sponsor
                </div>
                <v-img
                  src="@/assets/images/partners/faceit.png"
                  height="100px"
                >
                </v-img>
              </v-col>
              <v-col
                v-if="tournament.sponsrs !== undefined && tournament.sponsors.length > 0"
                md="2"
                sm="2"
                cols="12"
                class="align-self-start mt-8 ml-2"
              >
                <div class="text-left">
                  Sponsors
                </div>
              </v-col>
            </v-row>
          </v-img>
        </v-card>
        <v-card>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-card-text class="position-relative">
                <div class="d-flex justify-space-between flex-wrap pt-0">
                  <div class="me-2 mb-2">
                    <v-card-title class="mt-0 px-0">
                      Event Information
                    </v-card-title>
                    <v-card-subtitle class="text-m pa-0 pl-2 pt-0">
                      <span class="bold">Sign-up starts: </span>{{ unixToFormattedTime(tournament.signup_start) }}
                      <br>
                      <span class="bold">Sign-up ends: </span>{{ unixToFormattedTime(tournament.signup_end) }}
                      <br>
                      <span class="bold">Starts: </span>{{ unixToFormattedTime(tournament.start_time) }}
                      <!-- <br>
                      <span class="bold">Team format: </span>
                      <br>
                      <span class="bold">Buy-In per Team: </span> -->
                    </v-card-subtitle>
                    <v-card-title class="pt-4 px-0">
                      Tournament format
                    </v-card-title>
                    <v-card-subtitle class="text-m pa-0 pl-2">
                      {{ tournament.format }}
                      <!-- <span class="bold">01</span> - GROUPS (This is Demo)
                      <br>
                      <span class="bold">02</span> - SHOWDOWN STAGE (This is Demo)
                      <br>
                      <span class="bold"> 03</span> - FINALS STAGE (This is Demo) -->
                    </v-card-subtitle>
                    <!-- <v-card-title class="pt-4 px-0">
                      Prize distribution
                    </v-card-title>
                    <v-card-subtitle class="text-m pa-0 pl-2">
                      <span class="bold">1st</span> - x amount €
                      <br>
                      <span class="bold">2nd</span> - x amount €
                      <br>
                      <span class="bold">3rd</span> - x amount €
                    </v-card-subtitle> -->
                  </div>
                </div>
              </v-card-text>
            </v-col>

            <v-col
              v-for="(set, index) in tournament_teams"
              :key="index"
              cols="12"
              md="2"
              class="mt-6"
            >
              <template>
                <v-expansion-panels focusable>
                  <v-expansion-panel
                    v-for="(team, index2) in set"
                    :key="index2 + team.name"
                  >
                    <v-expansion-panel-header class="panel-header">
                      <v-img
                        width="20"
                        height="100%"
                        :src="'/flags/' + team.members[0].country + '.png'"
                        class="user-flag ml-1 mr-1"
                      ></v-img>
                      {{ team.name }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <span
                        v-for="(player, index3) in team.members"
                        :key="index3 + player.user_id"
                      >
                        <v-img
                          width="20"
                          height="100%"
                          :src="'/flags/' + player.country + '.png'"
                          class="user-flag ml-1 mr-1"
                        >
                        </v-img> {{ player.nickname }} <br>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div
      v-else
      class="text-center mt-10"
    >
      <v-progress-circular
        :size="100"
        indeterminate
        color="primary"
      >
      </v-progress-circular>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiAlertOutline,
  mdiBrightness1,
  mdiTrophy,
  mdiTwitch,
  mdiChevronUp,
  mdiChevronDown,
  mdiAlertOctagon,
} from '@mdi/js'

import { unixToFormattedTime, splitUp } from '@core/utils'
import axiosClient from '@/plugins/axios'

export default {
  data() {
    return {
      tournament: null,
      selectedId: this.$route.params.id,
    }
  },
  computed: {
    tournament_teams() {
      return splitUp(this.tournament.teams, 4)
    },
  },
  async mounted() {
    if (this.selectedId) {
      const response = await axiosClient.get(`${this.$API}/tournaments/${this.selectedId}`)
      if (!response.data.teams) return
      this.tournament = response.data
    }
  },
  setup() {
    return {
      unixToFormattedTime,
      icons: {
        mdiAlertCircleOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiBrightness1,
        mdiTrophy,
        mdiTwitch,
        mdiChevronUp,
        mdiChevronDown,
        mdiAlertOctagon,
      },
    }
  },
}
</script>
